import React, { Component } from 'react';

class About extends Component {

    render() {
        let image, name, desc, email;
        if (this.props.data) {

            const start = new Date(2012, 2);
            const now = new Date();

            let years = now.getFullYear() - start.getFullYear();
            if (now.getMonth() < start.getMonth()) {
                years -= 1;
            }

            image = this.props.data.image;
            name = this.props.data.name;
            desc = this.props.data.description.replace('##y##', years);
            email = this.props.data.email;
        }
        return (
            <section id="about">
                <div className="row">
                    <div className="three columns">
                        <img className="profile-pic" src={image} alt={name} />
                    </div>
                    <div className="nine columns main-col">
                        <h2>About Me</h2>
                        <p>{desc}</p>
                        <div className="row">
                            <h2>Contact Details</h2>
                            <p className="address">
                                <span>{email}</span>
                            </p>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default About;
