import React, { Component } from 'react';
import { Tooltip, Typography } from '@mui/material';

class Header extends Component {

    getPropertyValue(uri) {

        const segments = uri.split('.');

        let currentProperty = this.props;

        for (let i = 0; i < segments.length; i++) {
            currentProperty = currentProperty[segments[i]];
        }

        return currentProperty;
    }

    prepareUrl(url) {
        if (url.indexOf("{{") < 0 || url.indexOf("}}") < 0) {
            return url;
        }

        const regex = /{{([^{^}]*)}}/g;

        const segments = url.match(regex);

        let dynamicUrl = "";

        for (let i = 0; i < segments.length; i++) {
            dynamicUrl += this.getPropertyValue(segments[i].replace('{{', '').replace('}}', ''));
        }

        return dynamicUrl;
    }

    renderSocials() {

        return this.props.data
            ? this.props.data.social.map((s) => {

                return <Tooltip title={<Typography fontSize={16}>{s.title}</Typography>} arrow>
                    <li key={s.name}>
                        <a href={this.prepareUrl(s.url)} target={s.name}>
                            <img src={s.logo} alt={s.name} height={40} width={40}></img>
                        </a>
                    </li>
                </Tooltip >
            })
            : '';
    }


    render() {

        let name;
        let rolesString = "";

        if (this.props.data) {
            name = this.props.data.name;

            for (let i = 0; i < this.props.data.roles.length; i++) {
                rolesString += this.props.data.roles[i];
                if (i === this.props.data.roles.length - 1) {
                    rolesString += ".";
                } else if (i === this.props.data.roles.length - 2) {
                    rolesString += " & ";
                } else {
                    rolesString += ", ";
                }
            }

        }

        return (
            <header id="home">
                <div className="background-img"></div>

                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                        <li><a className="smoothscroll" href="#about">About</a></li>
                        <li><a className="smoothscroll" href="#resume">Resume</a></li>
                        <li><a className="smoothscroll" href="#work">Work</a></li>
                        <li><a className="smoothscroll" href="#skills">Skills</a></li>
                        <li><a className="smoothscroll" href="#certs">Certificates</a></li>
                        <li><a className="smoothscroll" href="#contact">Contact</a></li>
                    </ul>

                </nav>

                <div className="row banner">
                    <div className="banner-text">
                        <h1 className="responsive-headline">Hey, I'm {name}.</h1>
                        <h2>I am {rolesString}</h2>
                        <hr />
                        <ul className="social" id="contact">
                            {this.renderSocials()}
                        </ul>
                    </div>
                </div>
                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>
            </header>
        );
    }
}

export default Header;
