import './App.css';
import {Component} from "react";
import Header from './Components/Header';
import About from './Components/About';
import Resume from './Components/Resume';
import Footer from './Components/Footer';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resume: {}
        };
    }

    getResumeData() {
        fetch('/resume.json')
            .then(resp => {
                if (resp.ok) {
                    resp.json().then(data => {
                        this.setState({resume: data});
                    });
                }
            });
    }

    componentDidMount() {
        this.getResumeData();
    }

    render() {
        return (
            <div className="App">
                <Header data={this.state.resume.main} resume={this.state.resume.resume} />
                <About data={this.state.resume.main}/>
                <Resume data={this.state.resume.resume} config={this.state.resume.config}/>
                <Footer data={this.state.resume.main}/>
            </div>
        );
    }
}

export default App;
